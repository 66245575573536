import axios from "../../axiosInstance";
import envVariables from "../../utils/config.json";

const getLogedInUserDetails = async (accessToken: string) => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: `${
      process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
    }/users/user-details`,
    headers: { authorization: bearer },
  });
};

export {
  getLogedInUserDetails
};
