import { LogLevel } from "@azure/msal-browser";
import { USER_DETAILS, USER_IMAGE } from "../../const/api-paths";
import envVariables from "../../utils/config.json";

const graphEndpoint = process.env.REACT_APP_GRAPH_ENDPOINT || envVariables.REACT_APP_GRAPH_ENDPOINT;
const reactAppClient = process.env.REACT_APP_CLIENT_ID || envVariables.REACT_APP_CLIENT_ID;
const reactAppAuthority = process.env.REACT_APP_AUTHORITY || envVariables.REACT_APP_AUTHORITY;
const reactAppRedirectUrl = process.env.REACT_APP_REDIRECT_URL || envVariables.REACT_APP_REDIRECT_URL;
const applicationUrl = process.env.REACT_APP_APPLICATION_URL || envVariables.REACT_APP_APPLICATION_URL;
const powerbiPermissionsUrl = envVariables.REACT_APP_POWERBI_PERMISSIONS_URL;

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: reactAppClient ? reactAppClient : "",
    authority: reactAppAuthority ? reactAppAuthority : "",
    redirectUri: reactAppRedirectUrl ? reactAppRedirectUrl : "",
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            return;
          case LogLevel.Verbose:
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */

export const commonRequest = {
  scopes: [applicationUrl + "/access_as_user"],
};

export const authRequest = {
  scopes: ["User.Read"],
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
  graphMeEndpoint: graphEndpoint ? graphEndpoint + USER_DETAILS : "",
  graphMeImageEndpoint: graphEndpoint ? graphEndpoint + USER_IMAGE : "",
};
