import { createContext, useContext, useReducer } from "react";
import { getAccessToken } from "../services/authService";
import { useMsal } from "@azure/msal-react";
import { getLogedInUserDetails } from "../services/UserDetailsService/UserDetailService";

interface UserState {
    userDetails: LoggedInUser;
    status: "idle" | "loading" | "failed" | "authLoading";
}

interface LoggedInUser {
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    id: string;
    roleId: string;
    isAdmin: boolean;
}

type Action =
    | { type: 'FETCH_DATA_PENDING' }
    | { type: 'FETCH_DATA_FULFILLED'; payload: LoggedInUser }
    | { type: 'FETCH_DATA_REJECTED' }

interface ContextValue extends UserState {
    fetchLoggedInUserDetails: () => Promise<void>
}

const UserContext = createContext<ContextValue | undefined>(undefined);

type UserProviderProps = { children: React.ReactNode };

const initialState: UserState = {
    userDetails: {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        id: "",
        roleId: "",
        isAdmin: false,
    },
    status: "idle"
};

const reducer = (state: UserState, action: Action): UserState => {
    switch (action.type) {
        case 'FETCH_DATA_PENDING':
            return { ...state, status: 'loading' };
        case 'FETCH_DATA_FULFILLED':
            return { ...state, status: 'idle', userDetails: action.payload };
        case 'FETCH_DATA_REJECTED':
            return { ...state, status: 'failed' };
        default:
            return state;
    }
};

function LoggedInUserProvider({ children }: UserProviderProps) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { instance, accounts } = useMsal();

    const fetchLoggedInUserDetails = async () => {
        dispatch({ type: 'FETCH_DATA_PENDING' });
        try {
            const accessToken = await getAccessToken(instance, accounts[0]);
            const response = await getLogedInUserDetails(accessToken);
            const data = await response.data;
            dispatch({ type: 'FETCH_DATA_FULFILLED', payload: data });
        } catch (error) {
            dispatch({ type: 'FETCH_DATA_REJECTED' });
        }
    };

    return (
        <UserContext.Provider value={{ ...state, fetchLoggedInUserDetails }}>
            {children}
        </UserContext.Provider>
    );
};

const useLoggedInUserContext = (): ContextValue => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useDataContext must be used within a DataProvider');
    }
    return context;
};

export { useLoggedInUserContext, LoggedInUserProvider };