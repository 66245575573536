import { Flex, Grid, Popover, Select, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";
import {
  IconChevronDown, IconPointFilled
} from "@tabler/icons-react";
import { useEffect, useState, useCallback } from "react";
import { DateInput } from "@mantine/dates";
import Search from "../../common/Search";
import { FormProps, SelectOption } from "./type";
import Typography from "../../common/Typography";
import { RaidTypeEnum, TypographyTypes } from "../../../utils/enums";
import useAddItemFormStyles from "./styles";
import CheckboxHierarchy, { DataProps } from "../../common/CheckboxHierarchy";
import { useMsal } from "@azure/msal-react";
import { getAccessToken } from "../../../services/authService";
import {
  OwnerOptionsAPIParams,
  getImpactOptions,
  getItemTypesOptions,
  getOwnersOptions,
  getPriorityOptions,
  getProbabilityOptions, getStatusOptionsByType
} from "../../../services/AddItemService/addItems";
import moment from "moment";
import { DATE_FORMAT } from "../../../utils/constants/dateFormat";

const QuickItemForm = ({
  searchString,
  setSearchString,
  form,
  selected,
  values,
  setSelected,
  setValues,
  isFormDisabled = false
}: FormProps) => {
  const { t } = useTranslation();
  const { classes } = useAddItemFormStyles();

  const [statusOptions, setStatusOptions] = useState<SelectOption[]>([]);
  const [ownerOptions, setOwnerOptions] = useState<SelectOption[]>([]);
  const [itemTypeOptions, setItemTypeOptions] = useState<SelectOption[]>([]);
  const [itemType, setItemType] = useState(form.values.type.toString());
  const [impactOptions, setImpactOptions] = useState<SelectOption[]>([]);
  const [probabilityOptions, setProbabilityOptions] = useState<SelectOption[]>(
    []
  );
  const [priorityOptions, setPriorityOptions] = useState<SelectOption[]>([]);
  const { instance, accounts } = useMsal();

  useEffect(() => {
    getItemType();
  }, []);

  useEffect(() => {
    form.setValues({
      ...form.values,
      type: itemType,
    });
    if (itemType) {
      setImpactOptions([]);
      setPriorityOptions([]);
      setProbabilityOptions([]);
      getImpacts(itemType);
      getPriority(itemType);
      getProbability(itemType);
      getStatus(itemType);
    }
  }, [itemType]);

  useEffect(() => {
    form.setValues({
      ...form.values,
      project: selected.length > 0 ? findProjectLabelByValue(values, selected[0]) : "",
      projectId: selected[0],
    });
    if(selected.length > 0) {
      getOwners(selected[0], "");
    }
  }, [selected]);

  useEffect(() => {
    setItemType(form.values.type.toString());
  }, [form.values.id]);

  // REMOVE THIS
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  useEffect(() => {
    const ownerLabel = getLabelByValue(ownerOptions, form.values.owner);
    form.setValues({
      ...form.values,
      ownerLabel: ownerLabel,
    });
  }, [form.values.owner]);

  useEffect(() => {
    const statusLabel = getLabelByValue(statusOptions, form.values.status);
    form.setValues({
      ...form.values,
      statusLabel,
    });
  }, [form.values.status]);

  useEffect(() => {
    const impactLabel = getLabelByValue(impactOptions, form.values.impact);
    form.setValues({
      ...form.values,
      impactLabel,
    });
  }, [form.values.impact]);

  useEffect(() => {
    const prioritylabel = getLabelByValue(
      priorityOptions,
      form.values.priority
    );
    form.setValues({
      ...form.values,
      prioritylabel,
    });
  }, [form.values.priority]);

  useEffect(() => {
    const probabilityLabel = getLabelByValue(
      probabilityOptions,
      form.values.probability
    );
    form.setValues({
      ...form.values,
      probabilityLabel,
    });
  }, [form.values.probability]);

  useEffect(() => {
    const isCurrentOwnerInProject = ownerOptions.some((e) => e.value == form.values.owner)
    if(!isCurrentOwnerInProject) {
      form.setValues({
        ...form.values,
        owner: undefined,
        ownerLabel: undefined 
      })
    }
  }, [ownerOptions])

  async function getStatus(itemType: string) {
    const accessToken = await getAccessToken(instance, accounts[0]);
    getStatusOptionsByType(itemType, accessToken)
      .then(({ data }) => {
        setStatusOptions(
          data.map((lbl) => {
            return {
              value: `${lbl.key}`,
              label: lbl.value,
              colorCode: lbl.colorCode,
            };
          })
        );
      })
      .catch();
  }

  async function getOwners(containerId: string, searchString: string) {
    const accessToken = await getAccessToken(instance, accounts[0]);
    const requestBody: OwnerOptionsAPIParams = {containerId: containerId, search: searchString}
    getOwnersOptions(requestBody, accessToken)
      .then(({ data }) => {
        setOwnerOptions(
          data.map((lbl) => {
            return {
              value: `${lbl.id}`,
              label: lbl.display,
            };
          })
        );
      })
      .catch();
  }

  async function getItemType() {
    const accessToken = await getAccessToken(instance, accounts[0]);
    getItemTypesOptions(accessToken)
      .then(({ data }) => {
        setItemTypeOptions(
          data.map((lbl) => {
            return {
              value: `${lbl.key}`,
              label: lbl.value,
            };
          })
        );
      })
      .catch();
  }

  async function getImpacts(typeId: string) {
    const accessToken = await getAccessToken(instance, accounts[0]);
    getImpactOptions(typeId, accessToken)
      .then(({ data }) => {
        setImpactOptions(
          data.map((lbl) => {
            return {
              value: `${lbl.key}`,
              label: lbl.value,
            };
          })
        );
      })
      .catch();
  }

  async function getPriority(typeId: string) {
    const accessToken = await getAccessToken(instance, accounts[0]);
    getPriorityOptions(typeId, accessToken)
      .then(({ data }) => {
        setPriorityOptions(
          data.map((lbl) => {
            return {
              value: `${lbl.key}`,
              label: lbl.value,
            };
          })
        );
      })
      .catch();
  }

  async function getProbability(typeId: string) {
    const accessToken = await getAccessToken(instance, accounts[0]);
    getProbabilityOptions(typeId, accessToken)
      .then(({ data }) => {
        setProbabilityOptions(
          data.map((lbl) => {
            return {
              value: `${lbl.key}`,
              label: lbl.value,
            };
          })
        );
      })
      .catch();
  }

  const findProjectLabelByValue = (
    arr: DataProps[],
    targetValue: string
  ): string | null => {
    for (const item of arr) {
      if (item.key === targetValue) {
        return item.label;
      }
      if (item.children) {
        const result = findProjectLabelByValue(item.children, targetValue);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  function getLabelByValue(arr: SelectOption[], value: string) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].value === value) {
        return arr[i].label;
      }
    }
    return null; // Return null if the value is not found in the array
  }

  const getStatusClassName = useCallback(() => {
    var mainColor = "#adb5bd";
    var backgroundColor = "#ffffff";
    const itemIndex = statusOptions.findIndex((e) => {
      return e.value === form?.values?.status;
    });
    if (itemIndex !== -1 && statusOptions[itemIndex].colorCode) {
      mainColor = statusOptions[itemIndex].colorCode ?? mainColor;
      backgroundColor =
        statusOptions[itemIndex].colorCode + "4d" ?? backgroundColor;
    }
    return {
      icon: { color: mainColor },
      input: { background: backgroundColor },
    };
  }, [form?.values?.status, statusOptions]);

  // resetting impact, priority, probability on type change
  const onChangeType = (e: any) => {
    setItemType(e);
    const typeLabel = getLabelByValue(itemTypeOptions, e);
    const impactLabel = getLabelByValue(impactOptions, "");
    const prioritylabel = getLabelByValue(priorityOptions, "");
    const probabilityLabel = getLabelByValue(probabilityOptions, "");
    form.setValues({
      ...form.values,
      type: e,
      impact: null,
      priority: null,
      probability: null,
      typeLabel,
      impactLabel,
      prioritylabel,
      probabilityLabel,
      mitigationPlan: ""
    });
  };

  const calculatePriority = (probability: string, impact: string) => {
    const probabilityValue = probabilityOptions.find(
      option => option.value === probability
    );
    const impactValue = impactOptions.find(
      option => option.value === impact
    );
    const getProbabilityScore = (value?: string) => {
      switch(value?.toLowerCase()) {
        case 'high': return 3;
        case 'medium': return 2;
        case 'low': return 1;
        default: return 0;
      }
    };
  
    const getImpactScore = (value?: string) => {
      switch(value?.toLowerCase()) {
        case 'high': return 3;
        case 'medium': return 2;
        case 'low': return 1;
        default: return 0;
      }
    };
    
    const priorityScore = getProbabilityScore(probabilityValue?.label) * getImpactScore(impactValue?.label);
    console.log(priorityScore)
    if (priorityScore >= 6) return 'High';
    if (priorityScore >= 3) return 'Medium';
    return 'Low';
  };

  useEffect(() => {
    if (
      parseInt(itemType) === RaidTypeEnum.RISK && 
      form.values.probability && 
      form.values.impact
    ) {
      const impactLabel = getLabelByValue(impactOptions, form.values.impact);
      const probabilityLabel = getLabelByValue(probabilityOptions, form.values.probability);
      const calculatedPriority = calculatePriority(form.values.probability, form.values.impact);
      
      const priorityOption = priorityOptions.find(
        option => option.label.toLowerCase() === calculatedPriority.toLowerCase()
      );

      if (priorityOption && impactLabel && probabilityLabel) {
        const updatedValues = {
          ...form.values,
          priority: priorityOption.value,
          prioritylabel: priorityOption.label,
          impactLabel: impactLabel,
          probabilityLabel: probabilityLabel
        };
        
        form.setValues(updatedValues);
      }
    }
  }, [
    itemType,
    form.values.probability,
    form.values.impact,
    impactOptions.length,
    probabilityOptions.length,
    priorityOptions.length
  ]);

  return (
    <form
      onSubmit={form.onSubmit((_values: any) => { })}
      style={{ flexGrow: 1 }}
    >
      <Grid>
        <Grid.Col md={12} className={""}>
          <div style={{ marginTop: 0 }}>
            <Popover
              width="target"
              position="bottom"
              withArrow={false}
              shadow="md"
              onChange={(e) => {
                if (e) {
                  if (form.values.project === "") {
                    form.setValues({
                      ...form.values,
                      project: "Projects",
                    });
                  }
                } else {
                  if (form.values.project === "Projects") {
                    form.setValues({
                      ...form.values,
                      project: "",
                    });

                    form.setErrors({
                      ...form.errors,
                      project: t("quick_item_view.errors.project"),
                    });
                  }
                }
              }}
            >
              <Popover.Target>
                <TextInput
                  label="Project*"
                  placeholder="Project"
                  readOnly
                  {...form.getInputProps("project")}
                  rightSection={<IconChevronDown size={"1rem"}></IconChevronDown>}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: "none" } }}
                />
              </Popover.Target>
              {!isFormDisabled && <Popover.Dropdown>
                <Search
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                  onKeyDown={(e) => { }}
                  autoFocus
                  className={classes.projectSearch}
                  onClear={() => setSearchString("")}
                />

                <CheckboxHierarchy
                  isSingle={true}
                  values={values}
                  setValues={setValues}
                  selected={selected}
                  setSelected={setSelected}
                  searchText={searchString}
                />
              </Popover.Dropdown>}
            </Popover>
          </div>
          <div style={{ marginTop: -10 }}>
            <Select
              label="Type*"
              placeholder="Select Type"
              className={classes.projectSearch}
              rightSection={<IconChevronDown size="1rem" />}
              rightSectionWidth={30}
              styles={{ rightSection: { pointerEvents: "none" } }}
              data={itemTypeOptions}
              value={itemType}
              onChange={(e) => onChangeType(e)}
              readOnly={isFormDisabled}
            />
          </div>
          <div style={{ marginTop: 6 }}>
            <Select
              label="Status*"
              placeholder="Select Status"
              rightSection={<IconChevronDown size="1rem" />}
              rightSectionWidth={30}
              styles={{
                rightSection: { pointerEvents: "none" },
                ...getStatusClassName(),
              }}
              data={statusOptions}
              {...form.getInputProps("status")}
              readOnly={isFormDisabled}
              icon={<IconPointFilled size="1rem" />}
            />
          </div>
          <div style={{ marginTop: -10 }}>
            <Select
              label="Assigned To*"
              placeholder="Select Owner"
              className={classes.projectSearch}
              rightSection={<IconChevronDown size="1rem" />}
              rightSectionWidth={30}
              searchable
              styles={{ rightSection: { pointerEvents: "none" } }}
              data={ownerOptions}
              readOnly={isFormDisabled}
              {...form.getInputProps("owner")}
            />
          </div>
          <div style={{ marginTop: 6 }}>
            <DateInput
              valueFormat={DATE_FORMAT}
              minDate={new Date()}
              label="Target Date*"
              placeholder="Select Target Date"
              mx="auto"
              readOnly={isFormDisabled}
              {...form.getInputProps("targetDate")}
            />
          </div>
          {parseInt(itemType) === RaidTypeEnum.RISK ? (
            <div style={{ marginTop: -10 }}>
              <Select
                label="Probability"
                placeholder="Select Probability"
                className={classes.projectSearch}
                rightSection={<IconChevronDown size="1rem" />}
                rightSectionWidth={30}
                searchable
                styles={{ rightSection: { pointerEvents: "none" } }}
                data={probabilityOptions}
                readOnly={isFormDisabled}
                {...form.getInputProps("probability")}
              />
            </div>
          ) : null}
          {parseInt(itemType) !== RaidTypeEnum.ACTION ? (
            <>
              <div style={{ marginTop: -10 }}>
                <Select
                  label="Impact"
                  placeholder="Select Impact"
                  className={classes.projectSearch}
                  rightSection={<IconChevronDown size="1rem" />}
                  rightSectionWidth={30}
                  searchable
                  styles={{ rightSection: { pointerEvents: "none" } }}
                  data={impactOptions}
                  readOnly={isFormDisabled}
                  {...form.getInputProps("impact")}
                />
              </div>
            </>
          ) : null}
          {parseInt(itemType) !== RaidTypeEnum.ACTION && parseInt(itemType) !== RaidTypeEnum.RISK? (
            <>
              <div style={{ marginTop: -10 }}>
                <Select
                  label="Priority"
                  placeholder="Select Priority"
                  className={classes.projectSearch}
                  rightSection={<IconChevronDown size="1rem" />}
                  rightSectionWidth={30}
                  searchable
                  styles={{ rightSection: { pointerEvents: "none" } }}
                  data={priorityOptions}
                  readOnly={isFormDisabled}
                  {...form.getInputProps("priority")}
                />
              </div>
            </>
          ) : null}
          {parseInt(itemType) === RaidTypeEnum.RISK? (
            <>
              <div style={{ marginTop: 5 }}>
                <TextInput
                  label="Priority"
                  value={form.values.prioritylabel || ''}
                  placeholder="Calculated Priority"
                  readOnly
                  styles={{ 
                    input: { 
                      backgroundColor: '#f5f5f5',
                      color: '#333'
                    } 
                  }}
                />
              </div>
            </>
          ) : null}
          <Flex direction="row" gap={10} style={{ marginTop: 10, color: "grey" }}>
            <Typography type={TypographyTypes.label} text={"Created Date"} />
            <Typography
              type={TypographyTypes.label}
              text={moment(form.values.createdDate, "MMMM DD, YYYY").format(DATE_FORMAT)}
            />
          </Flex>
        </Grid.Col>
      </Grid>
    </form>
  );
};

export { QuickItemForm };
