import * as React from "react";
import {getPowerbiMyDashboardTiles, getPowerbiProjectDashboardTiles, getPowerbiLeadershipDashboardTiles } from "../services/PowerbiDashboardService/PowerbiDashboardService";
import { DashboardTilesResponse } from "../components/SideNavigation/types";
import { createContext, useReducer } from "react";
import { useMsal } from "@azure/msal-react";

interface DashboardState {
    myPowerbiDashboard: DashboardTilesResponse;
    projectPowerbiDashboard: DashboardTilesResponse;
    adminPowerbiDashboard: DashboardTilesResponse;
    loadState: "IDEAL" | "LOADED" | "ERROR" | "LOADING"
};

type Action =
| { type: "FETCH_DATA_MY_POWER_BI_DASHBOARD_PENDING" } 
| { type: "FETCH_DATA_MY_POWER_BI_DASHBOARD_FULLFILLED"; payload: DashboardTilesResponse } 
| { type: "FETCH_DATA_MY_POWER_BI_DASHBOARD_REJECTED" } 
| { type: "FETCH_DATA_PROJECT_POWER_BI_DASHBOARD_PENDING" } 
| { type: "FETCH_DATA_PROJECT_POWER_BI_DASHBOARD_FULLFILLED"; payload: DashboardTilesResponse } 
| { type: "FETCH_DATA_PROJECT_POWER_BI_DASHBOARD_REJECTED" } 
| { type: "FETCH_DATA_ADMIN_POWER_BI_DASHBOARD_PENDING" } 
| { type: "FETCH_DATA_ADMIN_POWER_BI_DASHBOARD_FULLFILLED"; payload: DashboardTilesResponse } 
| { type: "FETCH_DATA_ADMIN_POWER_BI_DASHBOARD_REJECTED" }

interface PowerBiDashboardContextValue extends DashboardState {
    fetchMyPowerBiDashboard: () => Promise<void>;
    fetchProjectPowerBiDashboard: () => Promise<void>;
    fetchAdminPowerBiDashboard: () => Promise<void>;
}

const PowerBiDashboardContext = createContext<PowerBiDashboardContextValue | undefined>(undefined);

type PowerbiDashboardProviderProps = { children: React.ReactNode };

const initialState: DashboardState = {
    myPowerbiDashboard: {tiles: [{id:"",subTitle:"",embedUrl:""}], accessToken: "", dashboardId:""},
    projectPowerbiDashboard: {tiles: [{id:"",subTitle:"",embedUrl:""}], accessToken: "", dashboardId:""},
    adminPowerbiDashboard: {tiles: [{id:"",subTitle:"",embedUrl:""}], accessToken: "", dashboardId:""},
    loadState: "IDEAL"
};

const PowerbiDashboardReducer= (state: DashboardState, action: Action): DashboardState => {
    switch (action.type) {
        case "FETCH_DATA_MY_POWER_BI_DASHBOARD_PENDING":
            return { ...state, loadState: "LOADING" };
        case "FETCH_DATA_MY_POWER_BI_DASHBOARD_FULLFILLED":
            return { ...state, loadState: "IDEAL", myPowerbiDashboard: action.payload };
        case "FETCH_DATA_MY_POWER_BI_DASHBOARD_REJECTED":
            return {...state, loadState: "ERROR"};
        case "FETCH_DATA_PROJECT_POWER_BI_DASHBOARD_PENDING":
            return {...state, loadState: "LOADING"};
        case "FETCH_DATA_PROJECT_POWER_BI_DASHBOARD_FULLFILLED":
            return {...state, loadState: "IDEAL", projectPowerbiDashboard: action.payload};
        case "FETCH_DATA_PROJECT_POWER_BI_DASHBOARD_REJECTED":
            return {...state, loadState: "ERROR"};
        case "FETCH_DATA_ADMIN_POWER_BI_DASHBOARD_PENDING":
            return {...state, loadState: "LOADING"};
        case "FETCH_DATA_ADMIN_POWER_BI_DASHBOARD_FULLFILLED":
            return {...state, loadState: "IDEAL", adminPowerbiDashboard: action.payload};
        case "FETCH_DATA_ADMIN_POWER_BI_DASHBOARD_REJECTED":
            return {...state, loadState: "ERROR"};
        default:
            return state;
    }
}

function PowerbiDashboardProvider({ children }: PowerbiDashboardProviderProps) {
    const [state, dispatch] = useReducer(PowerbiDashboardReducer, initialState);
    const { instance, accounts } = useMsal();
    
    const fetchMyPowerBiDashboard = async () => {
        dispatch({type: 'FETCH_DATA_MY_POWER_BI_DASHBOARD_PENDING'});
        try {
            const response = await getPowerbiMyDashboardTiles(instance, accounts);
            dispatch({type: 'FETCH_DATA_MY_POWER_BI_DASHBOARD_FULLFILLED', payload: response});
        } catch (error) {
            dispatch({type: 'FETCH_DATA_MY_POWER_BI_DASHBOARD_REJECTED'});
        }
    };
    const fetchProjectPowerBiDashboard = async () => {
        dispatch({ type: 'FETCH_DATA_PROJECT_POWER_BI_DASHBOARD_PENDING' });
        try {
            const response = await getPowerbiProjectDashboardTiles(instance, accounts);
            dispatch({ type: 'FETCH_DATA_PROJECT_POWER_BI_DASHBOARD_FULLFILLED', payload: response });
        } catch (error) {
            dispatch({ type: 'FETCH_DATA_PROJECT_POWER_BI_DASHBOARD_REJECTED' });
        }
    };
    const fetchAdminPowerBiDashboard = async () => {
        dispatch({ type: 'FETCH_DATA_ADMIN_POWER_BI_DASHBOARD_PENDING' });
        try {
            const response = await getPowerbiLeadershipDashboardTiles(instance, accounts);
            dispatch({ type: 'FETCH_DATA_ADMIN_POWER_BI_DASHBOARD_FULLFILLED', payload: response });
        } catch (error) {
            dispatch({ type: 'FETCH_DATA_ADMIN_POWER_BI_DASHBOARD_REJECTED' });
        }
    };
    return (
        <PowerBiDashboardContext.Provider value={{...state, fetchMyPowerBiDashboard, fetchProjectPowerBiDashboard, fetchAdminPowerBiDashboard}}>
            {children}
        </PowerBiDashboardContext.Provider>
    );
}

const usePowerbiDashboardContext = (): PowerBiDashboardContextValue => {
    const context = React.useContext(PowerBiDashboardContext);
    if (context === undefined) {
        throw new Error("useCount must be used within a CountProvider - powerbi");
    }
    return context;
}

export { usePowerbiDashboardContext, PowerbiDashboardProvider };